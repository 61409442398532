<template>
      <div class="error-container">
            <Error404 />
      </div>
</template>

<script>
import Error404 from "@/components/pages/errors/404.vue";
export default {
      name: "PageNotFound",
      components: {
            Error404
      },
};
</script>

<style lang="scss">
      .error-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
      }
</style>
