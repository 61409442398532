<template>
      <div class="vert-align">
            <img class="logo" src="@/assets/images/desktop/svg/404.svg" />
            <span class="mb-10 text-black">This page doesn't exist</span>
            <Btn @click="goTo('/')" id="close-modal" class="filters" size="lg" variant="black" icon="arrow-left" title="Go back to the home page"/>


            <!-- <button @click="goTo('/')">Go back to the home page</button> -->
      </div>
</template>

<script>
      import Btn from '@b/button/Button';
      export default {
            name: "Error404",
            components: {Btn}
      };
</script>

<style lang="scss" scoped>
.vert-align {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;

      span {
            @include toRem(font-size, 25);
            margin-bottom: 40px;

            @media screen and (max-width: 1366px) {
                  margin-bottom: 27px;
            }
      }
}
.logo {
      @media screen and (max-width: 1366px) {
            width: 28%;
      }
      width: 30%;
}
</style>